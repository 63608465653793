<template>
  <div class="row">
    <institution-view-template :institution-type="'Organization'" :in-nebraska="false"
                               :institution-data="organization"
                               :contact-users="contactUsers"/>
  </div>
  <div v-if="canEdit">
    <router-link :to="{name: 'EditOutstate', params: {idOrganization: idOrganization}}">
      <button class="btn-primary button">Edit This Organization</button>
    </router-link>

  </div>
  <div v-else>
    <hr/>
    <h6>
      <span style="font-weight: bold; color: black;">
        Contact the Educational Service Unit Coordinating Council to Edit/Update Contact Information
      </span>
    </h6>
  </div>
  <hr/>

</template>

<script>
import axios from "axios";
import {onBeforeMount, ref, computed} from "vue";
import {API_URL} from "../../../../../Constants";
import InstitutionViewTemplate from "@/views/SuperUserViews/EducationalOrganizationManagement/InstitutionViewTemplate";
import {useStore} from "vuex";

export default {
  name: "OustateOrganizationView",
  components: {InstitutionViewTemplate},
  props: {
    idOrganization: Number
  },
  setup(props, context) {
    const store = useStore();
    const authUser = computed(() => store.getters['auth/user']);

    let organization = ref({});
    const contactUsers = ref([]);

    onBeforeMount(() => {
      getOrganization();
    })

    async function getOrganization() {
      let get_uri = API_URL + "/outNeOrganization/displayById?_idOrganization=" + parseInt(props.idOrganization);
      await axios.get(get_uri)
          .then((result) => {
            let names = result.data.contactName !== null ? result.data.contactName.split(' ') : "";
            contactUsers.value[0] = {
              firstName: names[0],
              lastName: names[names.length - 1],
              email: result.data.contactEmail,
              idUser: 1
            };

            organization.value = prepareOrganizationData(result.data);
          })
    }

    function prepareOrganizationData(organization) {
      return {
        idOrganization: organization['idOrganization'],
        name: organization['organizationName'],
        primaryAddress: (organization['address'] !== null && organization['address'] !== undefined) ? organization['address'] : "",
        phoneNumber: (organization['phoneNumber'] !== null && organization['phoneNumber'] !== undefined) ? organization['phoneNumber'] : "",
        zipCode: (organization['postalCode'] !== null && organization['postalCode']) ? organization['postalCode'] : "",
        faxNumber: (organization['faxNumber'] !== null && organization['faxNumber'] !== undefined) ? organization['faxNumber'] : "",
        city: (organization['city'] !== null && organization['city'] !== undefined) ? organization['city'] : "",
        state: (organization['stateName'] !== null && organization['stateName'] !== undefined) ? organization['stateName'] : "",
        country: (organization['countryName'] !== null && organization['countryName'] !== undefined) ? organization['countryName'] : "",
        contactEmail: (organization['contactEmail'] !== null && organization['contactEmail'] !== undefined) ? organization['contactEmail'] : "",
        stateCode: (organization['stateCode'] !== null && organization['stateCode'] !== undefined) ? organization['stateCode'] : "-1",
        countryCode: (organization['stateCode'] !== null && organization['countryCode'] !== undefined) ? organization['countryCode'] : "US",
      }
    }

    const canEdit = computed(() => {
      if (authUser.value.position.roleHierarchy === 1) {
        return true;
      }
      if (authUser.value.outStateOrganization !== null)
        return authUser.value.outStateOrganization.idOutStateOrganization === parseInt(props.idOrganization);
      return false;
    })

    return {
      organization,
      authUser,
      contactUsers,
      canEdit,
    }
  }
}
</script>

<style scoped>

</style>